/* Global Styles for Home */
.home {
    font-family: 'Arial', sans-serif;
    color: #333;
    padding: 20px;
  }
  
  /* Hero Section */
  .hero-section {
    background-color: #f2f8fc; /* Light background */
    padding: 60px 20px;
    text-align: center;
    margin-bottom: 40px;
  }
  .hero-content {
    max-width: 900px;
    margin: 0 auto;
  }
  .hero-section h1 {
    font-size: 2.8rem;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  .hero-section p {
    font-size: 1.3rem;
    line-height: 1.6;
  }
  
  /* Features Section */
  .features-section {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 40px;
  }
  .feature-card {
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    flex: 1 1 300px;
    border-radius: 8px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  }
  .feature-card:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    background-color: #fdf8f2; /* subtle warm tint */
  }
  .feature-card h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  .feature-card p {
    font-size: 1.1rem;
    line-height: 1.5;
  }
  
  /* Content Cards */
  .content-card {
    background-color: #fff;
    margin: 20px auto;
    padding: 30px;
    border-radius: 8px;
    max-width: 900px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  }
  .content-card:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    background-color: #fdf8f2;
  }
  .content-card h2 {
    font-size: 2rem;
    margin-bottom: 15px;
  }
  .content-card p {
    font-size: 1.15rem;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  .content-card .screenshot {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  /* Call to Action Section */
  .cta-section {
    background-color: #e0f7fa; /* Light accent background */
    padding: 40px 20px;
    text-align: center;
    border-radius: 8px;
    margin: 40px auto;
    max-width: 800px;
  }
  .cta-section h2 {
    font-size: 2.2rem;
    margin-bottom: 20px;
  }
  .cta-section p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  .cta-button {
    background-color: #007BFF;
    color: #fff;
    padding: 15px 30px;
    border: none;
    border-radius: 50px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .cta-button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .features-section {
      flex-direction: column;
      align-items: center;
    }
  }
  