/* TermsAndConditions.css */

.terms-container {
    font-family: 'Arial', sans-serif;
    max-width: 900px;
    margin: 40px auto;
    padding: 20px 30px;
    background-color: #ffffff;
    color: #333;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .terms-container:hover {
    transform: scale(1.01);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  }
  
  /* Breadcrumb Navigation */
  .breadcrumb {
    font-size: 0.9rem;
    margin-bottom: 20px;
    color: #007BFF;
  }
  
  .breadcrumb a {
    text-decoration: none;
    color: #007BFF;
  }
  
  .breadcrumb a:hover {
    text-decoration: underline;
  }
  
  /* Headings and Dates */
  h1 {
    font-size: 2.2rem;
    margin-bottom: 10px;
    color: #222;
    text-align: center;
  }
  
  .dates {
    font-size: 0.95rem;
    text-align: center;
    margin-bottom: 30px;
    color: #555;
  }
  
  /* Section Styles */
  .section {
    margin-bottom: 30px;
  }
  
  .section h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #444;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 5px;
  }
  
  .section p {
    line-height: 1.6;
    font-size: 1rem;
    color: #555;
  }
  
  .section ul {
    margin: 10px 20px;
    list-style: disc;
    color: #555;
  }
  
  .section ul li {
    margin-bottom: 8px;
  }
  
  /* Link Styling */
  a {
    color: #007BFF;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  