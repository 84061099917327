/* Overall App */
.app {
  background-color: #fafafa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Header Styles */
.header {
  background-color: #ffffff;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.header-left {
  display: flex;
  align-items: center;
}

.logo-link {
  text-decoration: none;
}

.logo {
  max-height: 50px;
}

.desktop-nav .nav-links {
  display: flex;
  gap: 20px;
  margin-left: 20px;
}

.desktop-nav .nav-links li a {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
}

.header-right {
  position: relative;
}

.burger {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.burger span {
  display: block;
  width: 25px;
  height: 3px;
  background: #333;
}

.mobile-nav {
  display: none;
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 4px;
  z-index: 10;
}

.mobile-nav.active {
  display: block;
}

.mobile-nav .nav-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mobile-nav .nav-links li a {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }
  .burger {
    display: flex;
  }
}

/* Footer Styles */
.footer {
  margin-top: auto;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 20px 10px;
  font-size: 0.9rem;
}

.footer p {
  margin: 5px 0;
}

.footer a {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
}

.footer a:hover {
  text-decoration: underline;
}
